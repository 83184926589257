<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Peak Month Setup</h1>
            </div>
        </div>
        
        <div>
            
            <table class="table table-hover table-bordered" id="datatable">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Name</th>
                        <th>Last Update</th>
                        <th>Updated By</th>
                        <th width="10%">IsPeak</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id">
                        <td>{{item.Month}}</td>
                        <td>{{item.Name}}</td>
                        <td>{{item.LastUpdate}}</td>
                        <td>{{item.UpdatedBy}}</td>
                        <td>
                            <button type="button" class="btn btn-icon px-0"
                                    @click="togglePeak(item.Month,item.IsPeak)"
                                    :class="{'btn-success':item.IsPeak,'btn-danger':!item.IsPeak}">
                                <i :class="{'ion-android-checkmark-circle':item.IsPeak,'ion-android-cancel':!item.IsPeak}"></i>
                            </button>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>

        <div class="modal-mask" v-show="showForm">
            <div class="modal-wrapper">

                <div class="modal-dialog" >
                    <div class="modal-content" uib-modal-transclude="">
                        <div class="modal-content ">
                            <div class="modal-header">
                                <button type="button" class="close" @click="close" aria-label="Close">
                                    <em class="ion-ios-close-empty sn-link-close"></em>
                                </button>
                                <h4 class="modal-title">test</h4>
                            </div>
                            <div class="modal-body">stest</div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" @click="ok">ok</button>
                                <button type="button" class="btn btn-danger" @click="cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    //import "datatables.net-dt/js/dataTables.dataTables"
    //import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    //import $ from 'jquery';
    

    import { mapState,mapActions } from 'vuex'
    export default {
        name: `PeakMonth`,
        data: function () {
            return {
                LayoutModel: {},
                data: [],
                showForm: false,
            }
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',
                
                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAllUser();
                
            },            
            async getAllUser() {
                this.loading(true);
                const rs = await this.call({ Action: 'PeakMonth/List' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {                    
                    this.data = rs.Data;
                    console.log(this.data);
                }
            },
            async togglePeak(month, peak) {
                                
                if (!(this.checkAcl("Edit"))) {
                    this.showToast({ isShow: true, title: '', content: `No permission!`, type: 'error' });
                    return;
                }
                this.loading(true);
                console.log({ Object: { Month: month, IsPeak: !peak } });
                const rs = await this.call({ Action: 'PeakMonth/Edit', Object: { Month: month, IsPeak: !peak } })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    this.data[month - 1].IsPeak = !peak;
                    this.showToast({ isShow: true, title: '', content: ` save peak "${this.data[month - 1].Name}" successfull`, type: 'success' });
                }
            }
        },

        created() {
            
        },
        mounted() {            
            //if already login then start, 
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
        },
        watch: {
            //catch status from not login to logged in 
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            }
        }
    };
</script>
